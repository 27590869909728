@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Light'), local('OpenSans-Light'),
        url('../fonts/hinted-OpenSans-Light.woff2') format('woff2'),
        url('../fonts/hinted-OpenSans-Light.woff') format('woff'),
        url('../fonts/hinted-OpenSans-Light.ttf') format('truetype'),
        url('../fonts/hinted-OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url('../fonts/hinted-OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/hinted-OpenSans-Regular.woff') format('woff'),
        url('../fonts/hinted-OpenSans-Regular.ttf') format('truetype'),
        url('../fonts/hinted-OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
        url('../fonts/hinted-OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/hinted-OpenSans-Bold.woff') format('woff'),
        url('../fonts/hinted-OpenSans-Bold.ttf') format('truetype'),
        url('../fonts/hinted-OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}