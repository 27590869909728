.session-popup {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000, $alpha: 0.7);

  .inner-container {
    // background: linear-gradient(313.5deg, rgba(214, 240, 47, 1) 50%, rgba(12, 11, 8, 1) 50%);
    // background: #fff;
    width: 690px;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 80px;
    padding-bottom: 0;

    @include respond(768) {
      padding: 40px;
      padding-bottom: 0;
      max-height: 90vh;
      overflow-y: auto;
    }

    @include respond(468) {
      padding: 20px;
    }

    >i {
      position: absolute;
      left: 30px;
      top: 20px;
      color: #fff;
      cursor: pointer;
      font-size: 30px;

      @include respond(768) {
        left: 10px;
        top: 10px;
      }

      @include respond(468) {
        // color: rgba(214, 240, 47, 1);
        left: 30px;
        top: -7px;
      }
    }

    .white-container {
      // background-color: #fff;
      text-align: center;
      padding: 20px 10px;

      h3 {
        font-size: 40px;
        color: #A3BE36;
        text-transform: uppercase;
        font-weight: 700;

        @include respond(768) {
          font-size: 30px;
        }
      }

      h4 {
        font-size: 30px;
        color: #0C0B08;
        text-transform: uppercase;
        font-weight: 700;

        @include respond(768) {
          font-size: 24px;
        }
      }
    }

    .disclaimer {
      font-size: 14px;
      text-align: center;
      color: #0C0B08;
    }

    #mc_embed_signup {
      form {
        text-align: center;
        display: flex;
        justify-content: center;

        input {
          width: 100%;
          text-align: center;

          &#mc-embedded-subscribe {
            padding-right: 0;
            padding-left: 0;
            background: #0B79E1;
            border: 0;
            margin: 0;
            border-radius: 15px;
          }
        }
      }
    }
  }
}