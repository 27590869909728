//=======================================================================
// CUSTOM
//=======================================================================
// background: url(#{$images}footer_bg.jpg) no-repeat;
// bottom: 0;
// left: 50%;
// background-color: #e8e6cd;
// transform: translate(-50%, -50%);

/*----------  Header  ----------*/
#header {
  height: 103px;
  margin-bottom: 40px;

  .fixed-top {
    .menu {
      text-align: right;
    }
  }

  .logo {
    img {
      max-width: 160px;
      image-rendering: -webkit-optimize-contrast;
    }
  }
}

/*----------  End Of Header  ----------*/

//Main Homepage
.id-101 {
  #hp-slider {
    .col {
      &.slider-image {
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
    }

    .hp-slider {
      padding: 0;
      background-color: rgba(168, 168, 169, 0.13);
      margin: 0;

      .slick-slide {
        outline: 0;

        >div {
          padding: 0;
          font-size: 26px;
          font-weight: 300;
          color: #7a7a7a;
          position: relative;

          img {
            width: 100%;
          }

          &.slider-image {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              background: url(#{$images}gr_border.png) no-repeat;
              background-size: cover;
              width: 100%;
              height: 100%;
            }
          }
        }

        .hp-slider-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          background-color: rgba(255, 255, 255, .7);
          padding: 15px 0;

          p {
            margin: 0;
            padding-left: 50px;
            padding-right: 40px;
            line-height: 35px;
            font-size: 35px;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }

  #hp-categories {
    // padding-top: 10px;

    >.container {
      padding: 0;
      margin-top: -30px;
      margin-bottom: 150px;
      max-width: 1540px;
    }

    .hp-categories-module {
      .col-sm-4 {
        padding: 0;
        border: 3px solid #ee402d;
        border-radius: 40px;
        background-color: #fff;
        margin: 0 35px;
        width: 28%;
        position: relative;

        &:not(:last-child):before {
          content: '';
          background-image: url("../images/arrow.jpg");
          background-repeat: no-repeat;
          position: absolute;
          top: 38%;
          left: 99.3%;
          z-index: 1;
          display: block;
          width: 17%;
          height: 40%;
        }

        .icon-image {
          position: absolute;
          top: -50px;
          left: 25px;
        }

        .btn {
          background-color: #fff;
          border: 2px solid #ee402d;
          border-radius: 30px;
          margin-right: 54px;
          margin-bottom: -23px;
          width: 34%;
          font-weight: 700;
        }

        a {
          display: block;
          position: relative;
          color: #000;
          font-size: 17px;
          font-weight: 400;
          text-align: right;
        }

        .title-container {


          img,
          h3 {}

          img {
            margin-right: 20px;
            max-width: 150px;
          }

          h3 {
            font-size: 27px;
            font-weight: 400;
            padding: 10px 0;
            text-align: center;
            margin-left: 135px;
            text-transform: uppercase;
          }
        }

        .category-text {
          text-align: left;
          line-height: 22px;
          padding: 1px 21px;
          margin-top: -14px;
          height: 107px;

          p {
            color: #2a88aa;
          }
        }

        &:nth-child(1) {
          em {
            color: #2a88aa;
          }

          .title-container {
            border-color: #ee402d;
            color: #fff;
            background-color: #ee402d;
          }

          .btn {
            color: #ee402d;
          }
        }

        &:nth-child(2) {
          em {
            color: #2a88aa;
          }

          .title-container {
            border-color: #ee402d;
            color: #fff;
            background-color: #ee402d;
          }

          .btn {
            color: #ee402d;
          }
        }

        &:nth-child(3) {
          em {
            color: #2a88aa;
          }

          .title-container {
            border-color: #ee402d;
            color: #fff;
            background-color: #ee402d;
          }

          .btn {
            color: #ee402d;
          }
        }
      }
    }
  }

  #hp-map {
    h2 {
      text-align: center;
      margin-top: 70px;
      margin-bottom: 60px;

      span {
        color: #5e5e5e;
        font-size: 26px;
        font-weight: 400;
        border: 1px solid #a5a5a5;
        padding: 10px 40px;
        border-radius: 10px;
      }
    }

    ul {
      list-style-image: url(#{$images}list-bullet.png);
      columns: 2;
      column-gap: 50px;
      max-width: 500px;
      padding-left: 100px;
      margin-top: -200px;
      line-height: 32px;
    }
  }

  #hp-logos {
    .container {
      max-width: 98%;

      h2 {
        text-align: center;
        margin-top: 150px;
        margin-bottom: 60px;

        span {
          color: #5e5e5e;
          font-size: 26px;
          font-weight: 400;
          border: 1px solid #a5a5a5;
          padding: 10px 40px;
          border-radius: 10px;
        }
      }

      ul {
        padding: 0;
        margin-bottom: 90px;
        margin-top: 110px;

        .slick-arrow {
          opacity: 0;
        }

        &:hover {
          .slick-arrow {
            opacity: 1;
          }
        }

        li {
          img {
            margin: 0 auto;
            filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
            /* Firefox 10+, Firefox on Android */
            filter: gray;
            /* IE6-9 */
            -webkit-filter: grayscale(100%);
            /* Chrome 19+, Safari 6+, Safari 6+ iOS */
          }

          &:hover {
            img {
              filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
              -webkit-filter: grayscale(0%);
            }
          }
        }
      }
    }
  }
}

/*=====================================
=            Partners Page            =
=====================================*/

body.partners {
  #main-component {
    .top-image {
      height: 191px;
      margin-bottom: 80px;

      .top-image-wrapper {
        position: absolute;
        width: 100vw;
        right: 0;
        height: 191px;

        h1 {
          font-size: 57px;
          font-weight: 300;
          position: absolute;
          top: 50%;
          left: 20%;
          transform: translate(-50%, -50%);
          margin: 0;
          color: #fff;
        }
      }
    }

    .inner-body {
      padding: 0px 100px;

      >p {
        margin-bottom: 100px;
        max-width: 820px;
        color: #5e5e5e;
      }

      .logos {
        >div {
          width: 25%;
          margin-bottom: 40px;
          vertical-align: middle;
        }
      }
    }
  }
}

/*=====  End of Partners Page  ======*/

/*====================================
=            Not Homepage            =
====================================*/

body:not(.id-101) {
  #footer {
    margin-top: 100px;
  }
}

/*=====  End of Not Homepage  ======*/

/*==================================
=            About Page            =
==================================*/

body.about {
  #main-component {
    .top-image {
      height: 1005px;
      margin-top: -100px;

      .top-image-wrapper {
        position: absolute;
        width: 100vw;
        right: 0;
        height: 1005px;

        h1 {
          font-size: 30px;
          font-weight: 400;
          position: absolute;
          top: 20%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: 0;
          color: #a8a8a9;
          width: 100%;
          line-height: 48px;
          max-width: 1400px;
          text-align: center;
        }

        .footer-animation {
          font-size: 50px;
          z-index: 999;
          color: #0B79E1;
          position: absolute;
          bottom: 10px;
          left: 0;
          right: 0;
          text-align: center;

          &.bounce {
            position: absolute;
            bottom: 70px;
            left: 50%;
            width: 60px;
            height: 60px;
            margin-left: 0;
            animation: Ib 2s infinite;
            -webkit-animation: Ib 2s infinite;
            -moz-animation: Ib 2s infinite;
            -o-animation: Ib 2s infinite;
          }

          @-webkit-keyframes bounce {

            0%,
            20%,
            50%,
            80%,
            100% {
              -webkit-transform: translateY(0);
            }

            40% {
              -webkit-transform: translateY(-30px);
            }

            60% {
              -webkit-transform: translateY(-15px);
            }
          }

          @-moz-keyframes bounce {

            0%,
            20%,
            50%,
            80%,
            100% {
              -moz-transform: translateY(0);
            }

            40% {
              -moz-transform: translateY(-30px);
            }

            60% {
              -moz-transform: translateY(-15px);
            }
          }

          @-o-keyframes bounce {

            0%,
            20%,
            50%,
            80%,
            100% {
              -o-transform: translateY(0);
            }

            40% {
              -o-transform: translateY(-30px);
            }

            60% {
              -o-transform: translateY(-15px);
            }
          }

          @keyframes bounce {

            0%,
            20%,
            50%,
            80%,
            100% {
              transform: translateY(0);
            }

            40% {
              transform: translateY(-30px);
            }

            60% {
              transform: translateY(-15px);
            }
          }
        }
      }
    }

    .pre-text {
      font-size: 20px;
      line-height: 38px;
      color: #807f7f;
      font-weight: 300;
      text-align-last: center;
      text-align: justify;
    }

    .our-team {
      font-size: 20px;
      color: #807f7f;
      font-weight: 300;
      text-align: justify;

      strong {
        font-weight: 400;
      }

      p {
        line-height: 38px;
      }

      h2.section-title {
        text-align: center;
        margin: 60px 0px;
        text-transform: uppercase;

        span {
          background: #fff;
          color: #5e5e5e;
          font-size: 26px;
          font-weight: 400;
          border: 1px solid #a5a5a5;
          padding: 10px 40px;
          border-radius: 10px;
        }
      }

      h2 {
        text-align: center;
        font-size: 36px;
        font-weight: 400;
        color: #a8a8a9;
      }
    }

    .our-management {
      .about-module {
        >.row {
          margin-bottom: 20px;
        }
      }

      h2.section-title {
        text-align: center;
        margin: 60px 0px;
        text-transform: uppercase;

        span {
          background: #fff;
          color: #5e5e5e;
          font-size: 26px;
          font-weight: 400;
          border: 1px solid #a5a5a5;
          padding: 10px 40px;
          border-radius: 10px;
        }
      }


      h3.title-name {
        font-size: 34px;
        font-weight: 400;
        color: #807f7f;
        margin-top: 0;
      }

      .text {
        font-size: 20px;
        font-weight: 300;
        color: #807f7f;
        text-align: justify;
      }
    }
  }
}

/*=====  End of About Page  ======*/

/*====================================
=            Service Page            =
====================================*/

body.service {
  #main-component {
    p {
      font-size: 20px;
      color: #807f7f;
      font-weight: 300;
      margin-top: 0;

      strong {
        font-weight: 400;
      }
    }

    .top-image {
      height: 191px;

      .top-image-wrapper {
        position: absolute;
        width: 100vw;
        right: 0;
        height: 191px;

        h1 {
          font-size: 40px;
          font-weight: 400;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: 0;
          color: #fff;
          width: 100%;
          line-height: 52px;
          max-width: 1400px;
          text-align: left;

          .inline-block {
            vertical-align: middle;
            font-size: 33px;

            &:nth-child(2) {
              margin-left: 40px;
              margin-right: 50px;
            }
          }

          span {
            &.first-span {
              img {
                max-width: 170px;
              }
            }

            &.second-span {
              margin-top: -40px;
              font-size: 57px;
              font-weight: 300;
            }

            &.third-span {
              display: block;
              max-width: 67%;
              text-align: center;
              margin-top: -60px;
            }
          }
        }
      }
    }

    .first-section,
    .second-section {
      img {
        width: 100%;
      }
    }

    .first-section,
    .second-section,
    .third-section {
      margin-top: 30px;
      text-align: justify;

      >.row {
        >div {
          &:last-child {
            padding-left: 45px;
          }
        }
      }
    }

    .third-section {
      p {
        font-size: 28px;
        font-weight: 400;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &:not(:first-child) {
          font-size: 20px;
          font-weight: 300;
        }
      }

      ul {
        list-style-image: url(#{$images}list-bullet.png);

        li {
          font-size: 20px;
          font-weight: 300;
          color: #000000;
          opacity: 0.7;
          padding-left: 10px;
        }
      }
    }

    #hp-categories {
      padding-top: 50px;

      >.container {
        padding: 0;
      }

      .hp-categories-module {
        .col-sm-4 {
          padding: 0;

          a {
            display: block;
            position: relative;
            color: #000;
            font-size: 17px;
            font-weight: 400;
          }

          .title-container {
            // border-radius: 10px;
            // border: 1px solid;
            padding: 20px 30px;

            img,
            h3 {
              display: inline-block;
              vertical-align: middle;
            }

            img {
              margin-right: 20px;
              max-width: 150px;
            }

            h3 {
              font-size: 32px;
              font-weight: 400;
            }
          }

          .category-text {
            text-align: justify;
            line-height: 22px;
            padding: 15px;
          }

          &:nth-child(1) {
            em {
              color: #2a88aa;
            }

            .title-container {
              border-color: #2a88aa;
              color: #fff;
              background-color: #2a88aa;
            }
          }

          &:nth-child(2) {
            em {
              color: #c19d41;
            }

            .title-container {
              border-color: #c19d41;
              color: #fff;
              background-color: #c19d41;
            }
          }

          &:nth-child(3) {
            em {
              color: #745177;
            }

            .title-container {
              border-color: #745177;
              color: #fff;
              background-color: #745177;
            }
          }
        }
      }
    }
  }

  &.discovery {
    .second-section {
      * {
        color: #2a88aa $i;
      }
    }
  }

  &.library {
    .second-section {
      * {
        color: #C19D41 $i;
      }
    }
  }

  &.authentication {
    .second-section {
      * {
        color: #745177 $i;
      }
    }
  }

  &.fluid-body {

    .first-section,
    .second-section,
    .third-section {
      width: 100%;
      max-width: 1446px;
      margin: 0 auto;
    }
  }
}

/*=====  End of Service Page  ======*/

/*==================================
=            Contact Us            =
==================================*/

body {
  &.contact-us {
    #main-component {
      .top-image {
        height: 191px;
        margin-bottom: 80px;

        .top-image-wrapper {
          position: absolute;
          width: 100vw;
          right: 0;
          height: 191px;

          h1 {
            font-size: 57px;
            font-weight: 300;
            position: absolute;
            top: 50%;
            left: 29%;
            transform: translate(-50%, -50%);
            margin: 0;
            color: #fff;
          }
        }
      }

      .maps {
        color: #000;
        padding-bottom: 70px;

        h3 {
          margin-top: 0;
          font-size: 29px;
          font-weight: 400;
        }

        p {
          font-size: 18px;
          font-weight: 400;
        }
      }

      .forms {
        background-color: rgba(58, 57, 54, 0.59);
        padding-bottom: 30px;

        .forms-main-title {
          font-size: 57px;
          color: #fff;
          font-weight: 300;
          text-align: center;
        }

        .forms-wrapper {
          margin-bottom: 40px;

          >.row {
            >.col-sm-6 {
              h3 {
                font-size: 25px;
                color: #686868;
                font-weight: 400;
                text-align: center;
                background: #fff;
                border-radius: 10px;
                padding: 27px 10px;
                margin: 0;
                cursor: pointer;
              }
            }
          }

          .action-form {
            padding: 122px;
            padding-top: 0;
            margin-top: -26px;
            display: none;

            >h3 {
              text-align: center;
              margin-top: -16px;
              margin-bottom: 20px;

              span {
                background: #fff;
                color: #5e5e5e;
                font-size: 26px;
                font-weight: 400;
                border: 1px solid #a5a5a5;
                padding: 10px 40px;
                border-radius: 10px;
                width: 100%;
                display: inline-block;
                max-width: 600px;
                min-width: fit-content;
              }
            }

            .fox-container {
              .fox-item {
                margin-bottom: 3px;

                .controls {
                  width: 100%;

                  .checkbox {
                    padding: 0;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    margin-block: 15px;
                    color: #fff;
                    font-weight: 400;

                    input {
                      float: none;
                      display: inline-block;
                      width: 70px;
                      position: relative;
                      height: 30px;
                      margin-inline-end: -10px;

                      @include respond(1400) {
                        width: 130px;
                        margin-inline-end: 0;
                      }
                    }

                    .required {
                      color: #2471A3;
                      margin-inline-end: 10px;
                    }

                    .label-text,
                    a {
                      margin: 0;
                      color: #fff !important;
                    }

                    .modal_link {
                      text-decoration: underline;
                    }
                  }

                  input,
                  textarea,
                  .btn,
                  .chzn-container {
                    padding-right: 20px;
                    width: 100%;
                    border-radius: 0;
                    font-size: 20px;
                    color: #9b9a9e;
                    font-weight: 400;
                    background: #f8f7f6;
                    border: 1px solid #c9c9c9;
                    height: 40px;
                    padding-left: 20px;

                    &::-webkit-input-placeholder {
                      /* Chrome/Opera/Safari */
                      color: #bbbbbb;
                    }

                    &::-moz-placeholder {
                      /* Firefox 19+ */
                      color: #bbbbbb;
                    }

                    &:-ms-input-placeholder {
                      /* IE 10+ */
                      color: #bbbbbb;
                    }

                    &:-moz-placeholder {
                      /* Firefox 18- */
                      color: #bbbbbb;
                    }
                  }

                  textarea {
                    padding-top: 6px;
                    height: 103px $i;
                    margin-bottom: -5px;
                  }

                  .btn {
                    padding-right: 0;
                    padding-left: 0;
                    background: #696969;
                    border: 0;
                    margin: 0;

                    span {
                      font-size: 24px;
                      font-weight: 400;
                      color: #fff;
                      text-shadow: none;
                      text-transform: uppercase;
                    }
                  }

                  .chzn-container {
                    width: 100% $i;
                    padding: 0;

                    .chzn-single {
                      padding: 0;
                      background-color: transparent;
                      height: 40px;
                      line-height: 40px;
                      color: #bbbbbb;
                      background-image: none;
                      border-radius: 0;
                      border: 0;
                      padding-left: 20px;

                      >div {
                        width: 56px;
                        height: 48px;

                        b {
                          background: url(#{$images}form_select_arrow.jpg) no-repeat;
                        }
                      }
                    }

                    &.chzn-with-drop {
                      b {
                        transform: rotate(180deg);
                      }
                    }

                    .chzn-drop {
                      margin-top: 3px;

                      li {
                        line-height: 25px;
                        font-size: 18px;
                      }
                    }
                  }
                }

                &.fox-item-html {
                  text-align: justify;

                  p {
                    margin: 0;
                    font-size: 19px;
                    color: #fff;
                    font-weight: 400;
                  }
                }
              }

              .fox-column12 {
                margin: 30px 0px;
                padding-left: 20px;
                padding-right: 30px;
              }

              .fox-column6:first-child {
                p {
                  border-top: 1px solid #fff;
                  padding: 20px 30px 22px 20px;
                }

                .fox-item-radio {
                  .fox-label-inside-no-placeholder {
                    padding: 10px 20px;
                    width: 100%;
                    border-top: 1px solid #fff;
                    border-bottom: 1px solid #fff;
                    font-size: 20px;
                    font-weight: 400;
                    color: #fff;
                    margin-bottom: 10px;

                    .required {
                      color: #fff;
                      font-weight: 400;
                      font-size: 20px;
                    }
                  }

                  .fox-item-radio-label-stacked {
                    padding-left: 20px;
                    display: inline-block;
                    width: 50%;
                    color: #fff;
                    font-size: 19px;
                    margin-bottom: 10px;

                    label {
                      font-weight: 400 $i;
                      width: 100%;

                      input {
                        max-width: 20px;
                        height: 20px;
                        vertical-align: bottom;
                      }
                    }
                  }
                }
              }
            }
          }

        }

        .forms-note {
          font-size: 28px;
          font-weight: 300;
          color: #fff;
          text-align: center;
        }
      }
    }

    .fixed-top {
      .call-to-action {
        display: none;
      }
    }

    #footer {
      padding-top: 50px;
      margin-top: 0;

      .footer-cp {
        margin-top: 0;
      }
    }
  }

  &.contact-us-amazon {
    #main-component {
      .top-image {
        margin-bottom: 0;

        .top-image-wrapper {
          .item-image {
            height: 100%;

            img {
              height: 100%;
              object-fit: cover;
            }
          }

          h1 {
            background-color: #3e3c74;
            padding: 10px;
            color: #fff !important;
          }
        }
      }

      .actual-forms {
        form .fox-row {
          @media (min-width: 768px) {
            width: 84% !important;
          }
        }
      }

      .forms .forms-wrapper .action-form .fox-container .fox-item .controls {

        input,
        textarea {
          &::placeholder {
            color: #555 !important;
          }
        }
      }
    }
  }
}

/*=====  End of Contact Us  ======*/

/*==============================
=            Footer            =
==============================*/

#footer {
  padding: 0px;

  position: relative;

  >.container {
    background: url(#{$images}banner_bottom.png) no-repeat;
    background-size: cover;
    max-width: 100%;
    height: 680px;

    h2 {
      text-align: center;
      margin-top: -16px;
      margin-bottom: 20px;

      span {
        background: #fff;
        color: #5e5e5e;
        font-size: 26px;
        font-weight: 400;
        border: 1px solid #a5a5a5;
        padding: 10px 40px;
        border-radius: 10px;
      }
    }

    .fox-row {
      max-width: 80%;
    }

    .fox-container {
      padding: 0 122px 0 222px;

      .fox-item {
        margin-bottom: 5px;

        .controls {
          width: 100% !important;

          input,
          textarea,
          .btn,
          .chzn-container {
            padding-right: 20px;
            width: 100%;
            border-radius: 0;
            font-size: 20px;
            color: #9b9a9e;
            font-weight: 400;
            background: #f8f7f6;
            border: 1px solid #c9c9c9;
            height: 45px;
            padding-left: 20px;
            margin-bottom: 5px;
            border-radius: 5px;

            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: #bbbbbb;
            }

            &::-moz-placeholder {
              /* Firefox 19+ */
              color: #bbbbbb;
            }

            &:-ms-input-placeholder {
              /* IE 10+ */
              color: #bbbbbb;
            }

            &:-moz-placeholder {
              /* Firefox 18- */
              color: #bbbbbb;
            }
          }

          textarea {
            padding-top: 6px;
            height: 160px $i;
            margin-bottom: -5px;
          }

          .btn {
            padding-right: 0;
            padding-left: 0;
            background: #0B79E1;
            border: 0;
            margin: 0;
            border-radius: 15px;


            span {
              font-size: 24px;
              font-weight: 400;
              color: #000;
              text-shadow: none;
            }
          }

          .chzn-container {
            width: 100% $i;
            padding: 0;

            .chzn-single {
              padding: 0;
              background-color: transparent;
              height: 43px;
              line-height: 40px;
              color: #bbbbbb;
              background-image: none;
              border-radius: 0;
              border: 0;
              padding-left: 20px;

              >div {
                width: 56px;
                height: 48px;

                b {
                  background: url(#{$images}form_select_arrow.jpg) no-repeat;

                }
              }
            }

            &.chzn-with-drop {
              b {
                transform: rotate(180deg);
              }
            }

            .chzn-drop {
              margin-top: 3px;

              li {
                line-height: 25px;
                font-size: 18px;
              }
            }
          }
        }

        &.fox-item-html {
          text-align: justify;

          p {

            font-size: 28px;
            color: #000;
            font-weight: 400;
            margin-bottom: 20px;
          }
        }
      }

      .fox-column12 {}

      .fox-column6:first-child {
        p {
          font-size: 30px;
          color: #000;
        }

        .fox-item-radio {
          border-bottom: 1px solid #fff;
          padding-bottom: 10px;

          .fox-label-inside-no-placeholder {
            display: none;

            .required {
              display: none;
            }
          }

          .fox-item-radio-label-stacked {
            padding-left: 20px;
            display: inline;
            width: 100%;
            color: #fff;
            font-size: 19px;
            margin-bottom: 10px;

            label {
              font-weight: 400 $i;
              width: 100%;

              input {
                max-width: 20px;
                height: 20px;
                vertical-align: bottom;
              }
            }
          }
        }
      }
    }
  }

  .footer-cp {
    >.container {
      max-width: 100%;

      .row {
        padding: 42px 0;

        >div {
          text-align: center;

          &.col-sm-3 {
            padding-top: 10px;
          }

          &.col-sm-5 {
            padding-top: 20px;
            text-align: left;
            padding-left: 50px;
            color: #000;
          }

          &.theguy {
            padding-top: 15px;
          }
        }
      }
    }
  }
}

/*=====  End of Footer  ======*/

//Search Page
.view-search {
  .search {
    h1 {
      font-size: 50px;
    }

    * {
      color: #fff;
    }

    #searchForm {
      display: none;
    }

    .search-results {
      .result-title {
        font-size: 27px;
        text-decoration: underline;
      }

      .result-category {
        display: none;
      }
    }
  }
}

// News Section Homepage
.map-container {
  width: 100%;

  .hp-news-module {
    margin-bottom: 50px;

    .row {
      margin-bottom: 30px;

      &:nth-child(odd) {
        .image-div {
          float: right;

          img {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
          }
        }

        .text-div {
          float: left;
          padding: 30px 240px;

          @media (max-width: 1600px) {
            padding: 30px 100px;
          }

          @media (max-width: 468px) {
            padding: 11px 18px 11px 75px;
            width: 96%;
          }

          .title-name {
            font-size: 25px;
            font-weight: 700;
          }
        }
      }

      &:nth-child(even) {
        .image-div {
          img {
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
          }
        }

        .text-div {
          padding: 130px 240px;

          @media (max-width: 1600px) {
            padding: 30px 140px;
          }

          @media (max-width: 992px) {
            padding: 5px 95px;
          }

          @media (max-width: 468px) {
            padding: 11px 18px 11px 75px;
            width: 96%;
          }

          .title-name {
            font-size: 25px;
            font-weight: 700;
          }
        }
      }
    }
  }

}