//=======================================================================
// UNIVERSAL
//=======================================================================

html {
    // overflow-y: scroll; // scrollbar fix (prevent ugly left pull)
}

body {
    overflow-x: hidden;
    font-size: 18px;
    color: #000;


    * {
        font-family: 'Open Sans', sans-serif $i;
    }

    &.fluid-body {
        #main-component {
            >.container {
                max-width: 100%;
                padding: 0;

                .normal-container {
                    width: 100%;
                    max-width: 1140px;
                    padding: 0px 15px;
                    margin: 0 auto;
                }
            }
        }
    }
}

li:hover>a,
li>a,
#logo,
.fixed #logo,
.preloader,
#logo img,
.togglers h3:before,
ul.nav.menu>li a:after,
#ituran,
.id-101 .services .col-sm-4 a,
.border-image,
.product-info,
.hover-cont {
    -webkit-transition: all 300ms ease-in-out $i;
    -moz-transition: all 300ms ease-in-out $i;
    -ms-transition: all 300ms ease-in-out $i;
    -o-transition: all 300ms ease-in-out $i;
    transition: all 300ms ease-in-out $i;
}

img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    max-width: 100%;
}

.container {
    width: 100%;
    max-width: 1446px;
}

ul.nav.menu {
    padding: 0px;
    padding: 16px 0px 0px;
    text-align: right;

    &>li {
        display: inline-block;
        position: relative;
        vertical-align: top;

        &:not(:first-child) {
            margin-left: -5px;
        }

        &:not(:last-child) {
            margin-right: 25px;
        }

        &>ul {
            display: none;
            // display: block $i;
            position: absolute;
            // background-color: #a8a8a9;
            min-width: 100%;
            // box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            padding: 20px 0 0px;
            z-index: 1;
            padding-top: 0px;
            text-align: left;
            //border-radius: 10px;
            padding-top: 5px;

            li {
                display: block;
                position: relative;
                line-height: 30px;
                margin-bottom: 0px;
                overflow: hidden;

                &:not(:last-child) {
                    border-bottom: 1px solid #fff;
                }

                a {
                    color: #fff;
                    padding: 6px 20px;
                    font-size: 16px;
                    font-weight: 400;
                    display: block;
                    background: #12283D;
                    border-radius: 0;
                }

                &:hover,
                &.active {
                    a {
                        background-color: #fff;
                        color: #12283D;
                    }
                }

                &:first-child {
                    //border-radius: 10px 10px 0px 0px;
                }

                &:last-child {
                    //border-radius: 0px 0px 10px 10px;
                }
            }
        }

        &:hover {
            &>ul {
                display: block;
            }
        }

        a {
            font-weight: 400;
            font-size: 18px;
            color: #12283D;
            position: relative;
            line-height: 30px;
            padding: 4px 10px;
            text-transform: uppercase;
            border-bottom: 3px solid transparent;

            &.discovery {}

            &.library {}

            &.authentication {}
        }

        &.active,
        &:hover {
            >a {
                border-bottom: 3px solid #0B79E1;
            }
        }
    }
}

div[itemprop="articleBody"] {

    p,
    li {
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
}

.fixed-top {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    margin: 0;
    background: #fff;
    height: 103px;
    padding: 40px 0px;
    transition: padding .4s;

    .mobile-menu .con {
        margin-top: 0;
    }

    &.fixed {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 999999;
        padding: 20px 0px;


        .mobile-menu .con {
            margin-top: 4px;
        }
    }

    .call-to-action {
        position: absolute;
        right: 30px;
        top: 70px;
    }
}

.fixed {
    .call-to-action {
        top: 35px;
        transition: all .5s;
        margin-top: -35px;

        img {
            transition: all .5s;
            transform: scale(.75);
        }
    }
}

.fa {
    font-family: FontAwesome $i;
}

#offcanvas {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    z-index: -3;
    top: 0;
    right: 0%;
    background: transparent;

    .container {
        max-width: 100%;
        right: -100%;
        // background: rgba(0, 102, 165, 0.8);
        width: 100%;

        .search {
            input[name="searchword"] {
                border-radius: 50px 0px 0px 50px;
                padding-left: 10px;
                width: 73%;
                max-width: 1162px;
                border-right: 0;
                height: 55px;
                border: 2px solid #fff;
                background: $darkBlue;
                font-size: 26px;
                color: #fff;
                float: left;

                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #fff;
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #fff;
                }

                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #fff;
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #fff;
                }
            }
        }

        .close-btn {
            text-align: right;

            img {
                background: rgba(255, 255, 255, 0.7);
                border-radius: 50%;
            }
        }

        // ul li {
        // 	width: 100%;
        // 	text-align: right;
        // 	background: #0B79E1;
        // 	margin-bottom: 3px;
        // 	border-bottom: 0px;
        // 	border: 1px solid #fff;
        //     border-radius: 50px;

        // 	&.active {
        // 		i.fa {
        // 			color: #0B79E1 $i;
        // 		}
        // 	}

        // 	a {
        // 		border-bottom: 0px;
        // 		color: #fff;
        // 		text-transform: uppercase;
        // 		font-size: 26px;
        // 	    background-color: transparent;
        // 	    border-radius: 50px;
        // 	    line-height: 40px;

        // 	    &:after {
        // 	    	display: none;
        // 	    }

        // 	    .fa-caret-down {
        // 	    	display: none $i;
        // 	    }
        // 	}

        // 	ul {
        // 		width: 80%;
        // 		background-color: #0B79E1;
        // 		min-width: 80%;
        // 		position: relative;
        // 		margin: 0 auto;

        // 		li {
        // 			//background: @lightblue;
        // 			&:before {
        // 				display: none;
        // 			}
        // 		}
        // 	}
        // }
    }

    &.opened {
        z-index: 99999;
        background: rgba(168, 168, 169, 0.64);
        opacity: 1;
        overflow: hidden;
        padding-bottom: 200px;
        padding-top: 20px;
        right: 0;
        margin-top: 103px;
        position: fixed;
    }

    // &.fixed-opened {
    //        margin-top: 65px;
    // }

    // .nav.menu {
    // 	li {
    // 		position: relative;

    // 		&.parent {
    // 			.dropdown-menu {
    // 			    position: relative;
    // 			    background-color: transparent;
    // 			    border: 0px;
    // 			    box-shadow: none;
    // 			    width: 100%;
    // 			}
    // 		}

    // 		a {
    // 		    white-space: inherit;
    // 		    display: inline-block;
    // 		}

    // 		i {
    // 			cursor: pointer;
    // 			padding: 14px;
    // 			position: absolute;
    // 			left: 0;
    // 			color: #fff;
    // 			z-index: 0;
    // 			top: 0;
    // 		}
    // 	}
    // }

    .responsiveMenu1m {
        display: none $i;
    }

    .responsiveMenuTheme1d {
        display: block $i;

        li#item-109 {
            display: none;
        }
    }
}

// .ltr {
// 	#offcanvas {
// 		.nav.menu {
// 			li {
// 				a {
// 					display: block;
// 					text-align: left;
// 				}

// 				i {
// 					left: auto;
// 					right: 0;
// 				}

// 				&.parent {
// 					ul {
// 						li {
// 							a {
// 								text-transform: capitalize;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}	
// }

#offcanvas .nav.menu li:not(.deeper) a {
    display: block;
}

a {
    color: $greenColor;

    &:hover {
        text-decoration: none;
        // color: #000 $i;
        // background-color: transparent $i;
    }
}

.nopadding {
    padding: 0;
}

.margin20 {
    margin-bottom: 20px;
}

.inline-block {
    display: inline-block;
    vertical-align: top;
}

.center-text {
    text-align: center;
}

.mobile-menu {
    text-align: right;
}

.preloader {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999999999;
    background: #fff;
    width: 100vw;
    height: 100vh;
    padding-top: 44vh;
    opacity: 1;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
    }

    img,
    h2 {
        animation: fadeIn 1s infinite alternate;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    h2 {
        color: #7a7a7a;
        top: 56%;

        @media (max-width: 768px) {
            top: 63%;
        }
    }
}

.loaded {
    .preloader {
        opacity: 0;
        z-index: -1;
    }
}

.mobile-menu {
    text-align: right;
    height: 100%;
    // background: #fff;
    max-width: 100px;
    margin-right: 0;
    margin-left: auto;

    .con {
        width: auto;
        margin: 0 auto;
        -webkit-transition: all .7s ease;
        -moz-transition: all .7s ease;
        -ms-transition: all .7s ease;
        -o-transition: all .7s ease;
        transition: all .7s ease;
        cursor: pointer;
        display: inline-block;
        margin-top: 9px;
        margin-right: 20px;

        .bar {
            display: block;
            height: 5px;
            width: 50px;
            background: #0B79E1;
            margin: 10px auto;
            border-radius: 10px;
            -webkit-transition: all .7s ease;
            -moz-transition: all .7s ease;
            -ms-transition: all .7s ease;
            -o-transition: all .7s ease;
            transition: all .7s ease;
        }

        .middle {
            margin: 0 auto;
        }

        &.clicked {
            .top {
                -webkit-transform: translateY(15px) rotateZ(45deg);
                -moz-transform: translateY(15px) rotateZ(45deg);
                -ms-transform: translateY(15px) rotateZ(45deg);
                -o-transform: translateY(15px) rotateZ(45deg);
                transform: translateY(15px) rotateZ(45deg);
            }

            .bottom {
                -webkit-transform: translateY(-15px) rotateZ(-45deg);
                -moz-transform: translateY(-15px) rotateZ(-45deg);
                -ms-transform: translateY(-15px) rotateZ(-45deg);
                -o-transform: translateY(-15px) rotateZ(-45deg);
                transform: translateY(-15px) rotateZ(-45deg);
            }

            .middle {
                width: 0;
            }
        }
    }
}

//Form Sent
.fox-container {
    .alert.alert-success {
        color: #fff $i;
        background-color: $bordo $i;
        border-color: #fff $i;
        text-align: center;
        font-size: 34px;
        margin-top: 200px;

        .close {
            color: #fff $i;
            text-shadow: none $i;
            opacity: 1 $i;
            font-size: 30px $i;
        }


        .fox-messages {
            text-shadow: none;
            color: #fff;
        }
    }
}

// NEW DESIGN

.contact-image {
    text-align: center;
    margin-top: -68px;
}