/*===================================            Responsive            ===================================*/

@media (max-width: 1920px) {
    body.about {
        #main-component {
            .top-image {
                .top-image-wrapper {
                    h1 {
                        p {
                            margin-top: -40px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1800px) {
    .fixed-top {
        .call-to-action {
            img {
                width: 84%;
            }
        }
    }
    body.about {
        .call-to-action {
            right: -38px !important;
            img {
                width: 74% !important;
            }
        }
    }
}

@media (max-width: 1600px) {
    html {
        overflow-x: hidden;
    }
    .id-101 {
        #hp-slider {
            .hp-slider {
                .slick-slide {
                    > div {
                        font-size: 23px;
                    }

                    .hp-slider-text {
                        p {
                            line-height: 29px;
                            padding-left: 20px;
                            padding-right: 20px;
                            text-align: justify;
                        }
                    }
                }
            }
        }
    }
    .fixed-top {
        .call-to-action {
            right: 0 !important;
            top: 106px !important;
            a {
                img {
                    width: 70%;
                }
            }
        }
    }

    #footer {
        > .container {
            .fox-container {
                padding: 0 122px 0 122px;
            }
        }
    }
    .privacy-policy {
        margin-left: 20% !important;
    }

    body.service {
        #main-component {
            .top-image {
                .top-image-wrapper {
                    h1 {
                        max-width: 1200px;
                    }
                }
            }
        }
    }

    body.fluid-body {
        #main-component {
            > .container {
                padding: 0px 15px $i;
            }
        }
    }

    body.service {
        #main-component {
            #hp-categories {
                .hp-categories-module {
                    .col-sm-4 {
                        .title-container {
                            h3 {
                                font-size: 28px;
                            }
                        }
                    }
                }
            }

            .top-image {
                .top-image-wrapper {
                    h1 {
                        top: 34%;

                        .inline-block {
                            font-size: 26px;
                        }

                        span.third-span {
                            max-width: 70%;
                        }
                    }
                }
            }
        }
    }

    body.about {
        #main-component {
            .top-image {
                height: 100vh;
                margin-top: -70px;

                .top-image-wrapper {
                    height: 100vh;

                    h1 {
                        max-width: 75%;
                    }
                }
            }
        }
    }

    body.contact-us {
        #main-component > .container {
            padding: 0 $i;

            .forms .forms-wrapper .action-form {
                padding-bottom: 0;
            }
        }
    }
}

@media (max-width: 1400px) {
    .id-101 {
        #hp-slider {
            .hp-slider {
                .slick-slide {
                    > div {
                        font-size: 20px;
                    }

                    .hp-slider-text p {
                        line-height: 35px;
                    }
                }
            }
        }

        #hp-categories {
            > .container {
                margin-bottom: 80px;
            }
            .hp-categories-module {
                .col-sm-4 {
                    width: 32%;
                    &:not(:last-child) {
                        margin-bottom: 60px;
                    }
                    &:nth-child(2) {
                        margin-left: 120px;
                    }
                    .category-text {
                        height: 120px;
                    }
                    .icon-image {
                        width: 30%;
                    }
                    .title-container {
                        h3 {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }

    .fox-container {
        .fox-item-radio {
            .fox-item-radio-label-stacked {
                width: 100% $i;
            }
        }
    }

    body.service {
        #main-component {
            .top-image {
                .top-image-wrapper {
                    h1 {
                        max-width: 1000px;
                        top: 28%;

                        .inline-block {
                            font-size: 24px;
                        }

                        span.first-span img {
                            max-width: 130px;
                        }

                        span.third-span {
                            max-width: 73%;
                        }
                    }
                }
            }

            #hp-categories {
                .hp-categories-module {
                    .col-sm-4 {
                        .title-container {
                            img {
                                max-width: 120px;
                            }

                            h3 {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

    body.partners, body.contact-us {
        #main-component {
            .top-image {
                margin-bottom: 0;

                .top-image-wrapper {
                    h1 {
                        font-size: 47px;
                        top: 30%;
                    }
                }
            }
        }
    }

    body.about {
        #main-component {
            .top-image {
                .top-image-wrapper {
                    h1 {
                        max-width: 70%;
                        font-size: 26px;
                        line-height: 34px;
                    }
                }
            }
        }
    }

    body.contact-us {
        #main-component {
            .forms {
                .forms-main-title {
                    font-size: 50px;
                }

                .forms-wrapper {
                    > .row {
                        > .col-sm-6 {
                            h3 {
                                font-size: 21px;
                                padding: 17px 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    #footer .footer-cp > .container .row > div.col-sm-5 {
        display: grid;
    }
    .privacy-policy {
        margin-left: 0 !important;
    }
}

@media (max-width: 1200px) {
    .id-101, body.service {
        #hp-categories {
            .hp-categories-module {
                .col-sm-4 {
                    width: 37%;
                    .title-container {
                        img {
                            max-width: 90px $i;
                        }

                        h3 {
                            font-size: 20px $i;
                        }
                    }
                }
            }
        }
    }

    #footer {
        > .container {
            .fox-container {
                padding: 0;
            }
        }
    }

    ul.nav.menu > li a {
        font-size: 16px $i;
    }

    body.service {
        #main-component {
            .top-image {
                .top-image-wrapper {
                    h1 {
                        max-width: 900px;
                        top: 24%;

                        span.third-span {
                            max-width: 80%;
                        }
                    }
                }
            }
        }
    }

    body.partners {
        #main-component {
            .inner-body {
                .logos {
                    > div {
                        width: 33%;
                    }
                }
            }
        }
    }

    body.about {
        #main-component {
            .top-image {
                .top-image-wrapper {
                    img {
                        margin-top: 100px;
                    }

                    h1 {
                        max-width: 60%;
                    }
                }
            }
        }
    }

    body.contact-us, body.partners {
        #main-component {
            .top-image {
                .top-image-wrapper {
                    h1 {
                        font-size: 37px;
                        top: 30%;
                    }
                }
            }
        }
    }

    body.contact-us {
        #main-component {
            .maps {
                h3 {
                    font-size: 25px;
                }

                p {
                    font-size: 16px;
                }
            }

            .forms {
                .forms-wrapper {
                    > .row {
                        > .col-sm-6 {
                            h3 {
                                font-size: 18px;
                            }
                        }
                    }

                    .action-form {
                        padding: 15px;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    #header {
        .fixed-top {
            .call-to-action {
                display: none;
            }
            .menu {
                text-align: right;
            }
        }
    }
    .id-101 {
        #hp-categories {
            > .container {
                margin-top: 80px;
            }
            .hp-categories-module {
                .col-sm-4 {
                    width: 50%;
                    margin: 0 250px;
                    &:before {
                        width: 0 !important;
                    }
                    &:nth-child(2) {
                        margin: 0 250px 60px;

                    }
                    .icon-image {
                        width: 24%;
                    }
                }
            }
        }
    }

    body.service {
        #main-component {
            .top-image {
                .top-image-wrapper {
                    h1 {
                        max-width: 600px;
                        top: 23%;

                        .inline-block {
                            font-size: 18px;

                            img {
                                max-width: 90px $i;
                            }
                        }

                        span.third-span {
                            max-width: 92%;
                            margin-top: -40px;
                        }
                    }
                }
            }

            .col-sm-6 {
                width: 100%;
            }
        }
    }

    .id-101 {
        #hp-slider {
            .hp-slider {
                .slick-slide {
                    .hp-slider-text {
                        position: relative;
                        top: unset;
                        left: unset;
                        transform: inherit;
                        width: 100%;
                        p {
                            padding: 0 40px;
                            font-size: 18px;
                            text-align: left;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }

    body.partners {
        #main-component {
            .inner-body {
                padding: 0px;
            }
        }
    }

    body.contact-us {
        #main-component {
            .maps {
                .col-sm-6 {
                    width: 100%;
                }
            }

            .forms {
                .forms-switcher {
                    .col-sm-6 {
                        width: 100%;
                        margin-bottom: 6px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .id-101 {

        #hp-map h2 span {
            font-size: 22px;
            padding: 10px 15px;
            display: block;
        }
    }

    .id-101 {
        #hp-categories {
            > .container {
                margin-top: 80px;
            }
            .hp-categories-module {
                .col-sm-4 {
                    width: 75%;
                    margin: 0 auto;
                    &:before {
                        width: 0 !important;
                    }
                    &:nth-child(2) {
                        margin: 0 auto 60px;

                    }
                    .icon-image {
                        width: 24%;
                    }
                }
            }
        }
    }

    #header .fixed-top .col-sm-8 {
        position: absolute;
        top: 30px;
        right: 0;
    }

    #footer {
        > .container h2 {
            margin-bottom: 0;

            span {
                display: block;
                font-size: 22px;
            }
        }

        .footer-cp {
            height: auto;

            .row {
                > div {
                    padding: 0 $i;
                    margin-bottom: 10px;
                    text-align: center $i;
                }
            }
        }
    }

    body.service, body.partners, body.contact-us {
        #main-component {
            .top-image {
                height: auto;

                .top-image-wrapper {
                    position: relative;
                    width: 100%;
                    height: auto;

                    .item-image {
                        display: none;
                    }

                    h1 {
                        position: relative;
                        top: unset;
                        left: unset;
                        transform: inherit;
                        color: #807f7f;
                        text-align: center;

                        span {
                            width: 100% $i;
                            display: block $i;
                            margin: 0 $i;
                            font-size: 28px $i;
                        }

                        .first-span {
                            display: none $i;
                        }
                    }
                }
            }

            #hp-categories .hp-categories-module .col-sm-4 {
                padding: 0 $i;
            }
        }
    }

    body.partners {
        #main-component {
            .inner-body {
                .logos {
                    > div {
                        width: 50%;
                    }
                }
            }
        }
    }

    .preloader h2 {
        font-size: 100%;
    }

    body.about {
        #main-component {
            .top-image {
                margin-top: -40px;

                .top-image-wrapper {
                    h1 {
                        max-width: 90%;
                        font-size: 24px;
                        line-height: 30px;
                    }

                    img {
                        margin-top: 300px;
                    }

                    .footer-animation.bounce {
                        left: 45%;
                    }
                }
            }

            .our-team h2 {
                font-size: 28px;
            }

            .our-management h3.title-name {
                font-size: 30px;
                margin-top: 20px;
                text-align: center;
            }
        }
    }

    body.contact-us #main-component .top-image .top-image-wrapper h1 {
        margin-bottom: 20px;
    }

    body.contact-us {
        #main-component {
            .maps {
                .col-sm-6 {
                    margin-bottom: 20px;
                }
            }

            .forms {
                * {
                    font-size: 100% $i;
                }

                .col-sm-6 {
                    h3 {
                        padding: 10px $i;
                    }
                }
            }
        }
    }

    input, textarea, .btn span {
        font-size: 100% $i;
    }
}

@media (max-width: 468px) {
    .preloader h2 {
        font-size: 80%;
    }

    body.contact-us, body.partners, body.service {
        #main-component {
            .top-image {
                .top-image-wrapper {
                    h1 {
                        span {
                            font-size: 20px !important;
                        }
                    }
                }
            }
        }
    }

    .id-101 {
        #hp-categories {
            > .container {
                margin-top: 80px;
            }
            .hp-categories-module {
                .col-sm-4 {
                    &:not(:last-child) {
                        margin-bottom: 80px;
                    }
                    .icon-image {
                        width: 28%;
                    }
                    .title-container {
                        h3 {
                            margin-left: 0;
                        }
                    }
                    .category-text {
                        height: 100%;
                    }
                    .btn {
                        width: 61%;
                    }
                }
            }
        }
    }

    body.partners {
        #main-component {
            .inner-body {
                > p {
                    margin-bottom: 30px;
                }
            }
        }
    }

    body.about {
        #main-component {
            .our-management, .our-team {
                h2.section-title {
                    span {
                        display: block;
                        font-size: 22px;
                    }
                }
            }
        }
    }
}

/*=====  End of Responsive  ======*/

@media (max-width: 1100px) and (min-width: 992px) {
    #header {
        .fixed-top {
            .menu {
                margin-left: -60px;
            }
        }
    }
}